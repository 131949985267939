.cardContainer {
    max-width: 1400px;
    padding: 0 15px;
    margin: 0 auto;
}

.cards {
    display: flex;
    padding: 0;
    list-style: none;
    overflow-x: scroll;
    /* scroll-snap-type: x mandatory; */
    scrollbar-width: none; 
}


.card-images {
    display: flex;
    overflow-x: scroll;
    /* scroll-snap-type: x mandatory; */
    scrollbar-width: none; 
}

.card-images img {
    flex: 0 0 auto;
    width: 75px; /* Adjust the width as needed */
    height: 75px; /* Adjust the height as needed */
    margin-right: 9px; /* Adjust the spacing as needed */
    scroll-snap-align: start;
    border: 3px solid goldenrod;
    border-radius: 10px;
}

.card:hover {
    color: var(--white);
    background: var(--red);
}

.card .card-link-wrapper {
    margin-top: auto;
}

.card .card-link {
    display: inline-block;
    text-decoration: none;
    color: white;
    background: var(--red);
    padding: 6px 12px;
    border-radius: 8px;
    transition: background 0.2s;
}

.card:hover .card-link {
    background: var(--darkred);
}

.cards::-webkit-scrollbar {
    height: 12px;
}

.cards::-webkit-scrollbar-thumb,
.cards::-webkit-scrollbar-track {
    border-radius: 92px;
}

.cards::-webkit-scrollbar-thumb {
    background: var(--darkred);
}

.cards::-webkit-scrollbar-track {
    background: var(--thumb);
}

  
  /* @media (min-width: 500px) {
    .card {
      flex-basis: calc(50% - 10px);
    }
  
    .card:not(:last-child) {
      margin-right: 20px;
    }
  }
  
  @media (min-width: 700px) {
    .card {
      flex-basis: calc(calc(100% / 3) - 20px);
    }
  
    .card:not(:last-child) {
      margin-right: 30px;
    }
  }
  
  @media (min-width: 1100px) {
    .card {
      flex-basis: calc(25% - 30px);
    }
  
    .card:not(:last-child) {
      margin-right: 40px;
    }
  }
   */


   .bgStyles{
    background-color: #8d3318;
    display: flex;
    justify-content  : center;
    width: 100%;
    height:340vh
}

.bgStyles2{
    height:160vh;
    width:500px
}
.borderCss{
    height:32vh;
    position:absolute;
    top:718px;
    right:32%
}
.borderCss img{
    width:470px;height:36vh
}
.imgPostion{
     height: 68vh; 
     position: relative;
    
}
.imgPostion2{
    position: absolute; 
    height: 30vh;
     width: 90%;
      bottom: 75px;
      left: 22px 
}
@media screen and (max-width: 550px){
    .bgStyles{
        background-color: black;
        display: flex;
        justify-content  : center;
        width: 100%;
        height:154vh
    }
    
    .bgStyles2{
        height:155vh;
        width:500px
    }
    .borderCss{
        height:32vh;
        position:absolute;
        top:95%;
        right:5%
    }
    .borderCss img{
        width:100%;height:36vh
    }
    .imgPostion{
        height: 57vh; 
        position: relative;
      
   }
   .imgPostion2{
    position: absolute; 
    height: 30vh;
     width: 90%;
      bottom: 0px;
      left: 15px 
}
}